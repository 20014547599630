import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ms from 'ms';

const categoryOptions = ['Economy', 'Virtual Token', 'Moderation', 'Utility'];

const Commands = () => {
  const [commands, setCommands] = useState([]);
  const [filteredCommands, setFilteredCommands] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Economy');
  const [selectedCommand, setSelectedCommand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCommands = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/commands`);

        const enabledCommands = response.data
          .filter(command => !command.disabled)
          .sort((a, b) => a.name.localeCompare(b.name));

        setCommands(enabledCommands);
        setFilteredCommands(
          enabledCommands.filter(command => command.category === 'Economy')
        );
      } catch {
        setError('Failed to fetch commands');
      } finally {
        setLoading(false);
      }
    };

    fetchCommands();
  }, []);

  useEffect(() => {
    const categoryMapping = {
      Economy: 'economy',
      "Virtual Token": 'virtual token',
      Moderation: 'moderation',
      Utility: 'utility',
    };

    const selectedType = categoryMapping[selectedCategory];
    if (selectedType) {
      setFilteredCommands(
        commands.filter(command => command.type && command.type.toLowerCase() === selectedType)
      );
    }
  }, [selectedCategory, commands]);

  if (loading) return <p>Loading commands...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex container mx-auto py-8">
      {/* Left Side: Commands List */}
      <div className="flex-1 mr-4">
        <h2 className="text-3xl font-extrabold mb-4 text-accentCyan">Available Commands</h2>

        {/* Category Selector Menu */}
        <div className="flex mb-4 space-x-2">
          {categoryOptions.map((category) => (
            <button
              key={category}
              className={`px-3 py-1 rounded text-sm ${
                selectedCategory === category
                  ? 'bg-accentCyan text-black'
                  : 'bg-darker text-lightGray hover:bg-accentCyan hover:text-black'
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Command List */}
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredCommands.length === 0 ? (
            <p className="text-lightGray">No commands found for the selected category.</p>
          ) : (
            filteredCommands.map((command) => (
              <div
                key={command._id}
                className="bg-darker p-4 rounded shadow-lg cursor-pointer transition-transform transform hover:scale-105"
                onClick={() => setSelectedCommand(command)}
              >
                <h4 className="text-lg font-semibold text-accentCyan">/{command.name}</h4>
                <p className="text-sm text-lightGray">{command.description}</p>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Command Details */}
      <div className="w-1/3 bg-darker p-4 rounded shadow-lg ml-auto">
        {selectedCommand ? (
          <>
            <h3 className="text-xl font-bold text-accentCyan mb-2">/{selectedCommand.name}</h3>
            <p className="text-sm text-lightGray mb-2">{selectedCommand.description}</p>
            <p className="text-sm text-lightGray mb-2">
              <strong>Cooldown:</strong> {ms(selectedCommand.cooldown)}
            </p>
            <p className="text-sm text-lightGray mb-2">
              <strong>Premium Cooldown:</strong> {ms(selectedCommand.premiumCooldown)}
            </p>
            <p className="text-sm text-lightGray mb-2">
              <strong>Ultimate Cooldown:</strong> {ms(selectedCommand.ultimateCooldown)}
            </p>
          </>
        ) : (
          <p className="text-lightGray">Click on a command to see more details.</p>
        )}
      </div>
    </div>
  );
};

export default Commands;