import React, { useState } from 'react';

const productsData = {
  Subscriptions: [
    {
      name: 'Wabbit Pro',
      price: 4.99,
      icon: 'https://cdn.discordapp.com/emojis/1257774048792805501.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop',
      benefits: ['ZMA Supporter Kits ・ Self-serve + 1 hour cooldown'],
    },
    {
      name: 'Wabbit Elite',
      price: 9.99,
      icon: 'https://cdn.discordapp.com/emojis/1246238861689098341.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop',
      benefits: [
        'ZMA Supporter Kits ・ Reduced cooldown - only 20 minutes!',
        'Premium Economy ・ Multipliers, discounts, reduced cooldowns, and other perks',
        'Exclusive Giveaways ・ Entry to exclusive giveaways (including WabbitCash, max gear, and more)',
        'Discord Perks ・ You get to look cool, too!',
      ],
    },
    {
      name: 'Wabbit Ultimate',
      price: 14.99,
      icon: 'https://cdn.discordapp.com/emojis/1282076754877349928.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop',
      benefits: [
        'ZMA Ultimate Kits ・ Better gear, reduced cooldown!',
        'Premium Economy ・ MORE Multipliers, discounts, reduced cooldowns, and other perks',
        'Increased Bet Limit ・ 50% higher economy bet limit',
        'Exclusive Giveaways ・ Entry to exclusive giveaways (including WabbitCash, max gear, and more)',
        'Discord Perks ・ You get to look cool, too!',
      ],
    },
  ],
  WabbitPass: [
    {
      name: 'WabbitPass',
      price: 9.99,
      icon: 'https://cdn.discordapp.com/emojis/1281726489816993924.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
  ],
  'In-Game Products': [
    {
      name: 'Flower Hat',
      price: 4.99,
      description: '1x Flower Hat will immediately be added to your inventory (and can be claimed using /claim)',
      icon: 'https://cdn.discordapp.com/emojis/1245772443256098868.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: 'Blue Crown',
      price: 8.99,
      description: '1x Blue Crown will immediately be added to your inventory (and can be claimed using /claim)',
      icon: 'https://cdn.discordapp.com/emojis/1245772210371821578.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: 'Frost Gear',
      price: 12.99,
      description: 'Frost Set (1x cake) will immediately be added to your inventory (and can be claimed using /claim)',
      icon: 'https://cdn.discordapp.com/emojis/1258516305426911373.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: 'Midas Gear',
      price: 12.99,
      description: 'Midas Set (1x cookie) will immediately be added to your inventory (and can be claimed using /claim)',
      icon: 'https://cdn.discordapp.com/emojis/1245772209419587706.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: 'Hades Gear',
      price: 12.99,
      description: 'Hades Set (1x bread) will immediately be added to your inventory (and can be claimed using /claim)',
      icon: 'https://cdn.discordapp.com/emojis/1258516282442121358.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
  ],
  Chests: [
    {
      name: 'Diamond Chest',
      price: 2.99,
      description: '1x Diamond Chest (guaranteed good item) will immediately be added to your inventory (and can be claimed using /use)',
      icon: 'https://cdn.discordapp.com/emojis/1066955523192279070.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: 'Exotic Chest',
      price: 9.99,
      description: '1x Exotic Chest (guaranteed HADES/MIDAS/FROST set) will immediately be added to your inventory (and can be claimed using /use)',
      icon: 'https://cdn.discordapp.com/emojis/1066955610236669982.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
  ],
  WabbitCash: [
    {
      name: '1,000,000 WabbitCash',
      price: 2.99,
      description: '1,000,000 WabbitCash will immediately be added to your bank',
      icon: 'https://cdn.discordapp.com/emojis/1271879470734770210.webp?size=96&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: '5,000,000 WabbitCash',
      price: 14.99,
      description: '5,000,000 WabbitCash will immediately be added to your bank',
      icon: 'https://cdn.discordapp.com/emojis/1271622605211959417.webp?size=64&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
    {
      name: '10,000,000 WabbitCash',
      price: 27.99,
      description: '10,000,000 WabbitCash will immediately be added to your bank',
      icon: 'https://cdn.discordapp.com/emojis/1271622651978317874.webp?size=64&quality=lossless',
      purchaseLink: 'https://discord.com/channels/735604263836975145/shop/1281045379084845077',
    },
  ],
};

const categoryOptions = ['Subscriptions', 'WabbitPass', 'In-Game Products', 'Chests', 'WabbitCash'];

const Products = () => {
  const [selectedCategory, setSelectedCategory] = useState('Subscriptions');
  const [selectedProduct, setSelectedProduct] = useState(null);

  const products = (productsData[selectedCategory] || []).sort((a, b) => a.price - b.price);

  return (
    <div className="flex container mx-auto py-8">
      {/* Left Side: Products List */}
      <div className="flex-1 mr-4">
        <h2 className="text-3xl font-extrabold mb-4 text-accentCyan">Available Products</h2>

        {/* Category Selector Menu */}
        <div className="flex mb-4 space-x-2">
          {categoryOptions.map((category) => (
            <button
              key={category}
              className={`px-3 py-1 rounded text-sm ${
                selectedCategory === category
                  ? 'bg-accentCyan text-black'
                  : 'bg-darker text-lightGray hover:bg-accentCyan hover:text-black'
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Product List */}
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {products.length === 0 ? (
            <p className="text-lightGray">No products found for the selected category.</p>
          ) : (
            products.map((product) => (
              <div
                key={product.name}
                className="bg-darker p-4 rounded shadow-lg cursor-pointer transition-transform transform hover:scale-105"
                onClick={() => setSelectedProduct(product)}
              >
                <img src={product.icon} alt={product.name} className="w-16 h-16 mb-2 object-contain" />
                <h4 className="text-lg font-semibold text-accentCyan">{product.name}</h4>
                <p className="text-sm text-lightGray">
                  ${product.price.toFixed(2)}{' '}
                  {selectedCategory === 'WabbitPass'
                    ? 'per season'
                    : selectedCategory === 'In-Game Products' || selectedCategory === 'Chests' || selectedCategory === 'WabbitCash'
                    ? 'one-time purchase'
                    : 'per month'}
                </p>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Right Side: Product Details - Smaller Box */}
      <div className="w-1/3 bg-darker p-4 rounded shadow-lg ml-auto">
        {selectedProduct ? (
          <>
            <h3 className="text-xl font-bold text-accentCyan mb-2">{selectedProduct.name}</h3>
            <img src={selectedProduct.icon} alt={selectedProduct.name} className="w-20 h-20 mb-4 object-contain" />
            <p className="text-2xl font-bold text-lightGray mb-4">
              ${selectedProduct.price.toFixed(2)}{' '}
              {selectedCategory === 'WabbitPass'
                ? 'per season'
                : selectedCategory === 'In-Game Products' || selectedCategory === 'Chests' || selectedCategory === 'WabbitCash'
                ? 'one-time purchase'
                : 'per month'}
            </p>
            {selectedProduct.description && <p className="text-sm text-lightGray mb-2">{selectedProduct.description}</p>}
            {selectedProduct.benefits && (
              <>
                <h4 className="text-lg font-semibold text-accentCyan mb-2">Benefits:</h4>
                <ul className="list-disc list-inside text-sm text-lightGray mb-2">
                  {selectedProduct.benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                  ))}
                </ul>
              </>
            )}
            {selectedProduct.purchaseLink && (
              <a
                href={selectedProduct.purchaseLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-sm text-accentCyan underline"
              >
                Purchase Here
              </a>
            )}
          </>
        ) : (
          <p className="text-lightGray">Click on a product to see more details.</p>
        )}
      </div>
    </div>
  );
};

export default Products;