import React, { useEffect, useState } from 'react';
import axios from 'axios';

const categoryOptions = ['Gear', 'Utilities', 'Collectables', 'Chests', 'Mobs', 'Miscellaneous'];

const Items = () => {
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('Gear');
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/items`);
        setItems(response.data);
        setFilteredItems(response.data.filter(item => item.type.toLowerCase() === 'gear').sort((a, b) => b.price - a.price));
      } catch (err) {
        setError('Failed to fetch items');
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, []);

  useEffect(() => {
    const categoryMapping = {
      Gear: 'gear',
      Utilities: 'utility',
      Collectables: 'collectable',
      Chests: 'chest',
      Mobs: 'mob',
    };

    const selectedType = categoryMapping[selectedCategory];

    if (selectedType) {
      setFilteredItems(
        items
          .filter(item => item.type.toLowerCase() === selectedType)
          .sort((a, b) => b.price - a.price)
      );
    } else {
      setFilteredItems(
        items
          .filter(item => !Object.values(categoryMapping).includes(item.type.toLowerCase()))
          .sort((a, b) => b.price - a.price)
      );
    }
  }, [selectedCategory, items]);

  if (loading) return <p>Loading items...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="flex container mx-auto py-8">
      {/* Left Side: Items List */}
      <div className="flex-1 mr-4">
        <h2 className="text-3xl font-extrabold mb-4 text-accentCyan">Available Items</h2>

        {/* Category Selector Menu */}
        <div className="flex mb-4 space-x-2">
          {categoryOptions.map((category) => (
            <button
              key={category}
              className={`px-3 py-1 rounded text-sm ${
                selectedCategory === category
                  ? 'bg-accentCyan text-black'
                  : 'bg-darker text-lightGray hover:bg-accentCyan hover:text-black'
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>

        {/* Item List */}
        <div className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          {filteredItems.length === 0 ? (
            <p className="text-lightGray">No items found for the selected category.</p>
          ) : (
            filteredItems.map((item) => (
              <div
                key={item._id}
                className="bg-darker p-4 rounded shadow-lg cursor-pointer transition-transform transform hover:scale-105"
                onClick={() => setSelectedItem(item)}
              >
                <img src={item.icon} alt={item.name} className="w-16 h-16 mb-2 object-contain" />
                <h4 className="text-lg font-semibold text-accentCyan">{item.name}</h4>
                <p className="text-sm text-lightGray">{item.price.toLocaleString()} WabbitCash</p>
              </div>
            ))
          )}
        </div>
      </div>

      {/* Right Side: Item Details - Smaller Box */}
      <div className="w-1/3 bg-darker p-4 rounded shadow-lg ml-auto">
        {selectedItem ? (
          <>
            <h3 className="text-xl font-bold text-accentCyan mb-2">{selectedItem.name}</h3>
            <img src={selectedItem.icon} alt={selectedItem.name} className="w-20 h-20 mb-4 object-contain" />
            <p className="text-sm text-lightGray mb-2">{selectedItem.description}</p>
            <p className="text-sm text-lightGray mb-2"><strong>Price:</strong> {selectedItem.price.toLocaleString()} WabbitCash</p>
            <p className="text-sm text-lightGray mb-2"><strong>Type:</strong> {selectedItem.type.charAt(0).toUpperCase() + selectedItem.type.slice(1).toLowerCase()}</p>
          </>
        ) : (
          <p className="text-lightGray">Click on an item to see more details.</p>
        )}
      </div>
    </div>
  );
};

export default Items;